import { Fragment, useState, useEffect } from 'react';

import usePeriodComparison from '~/hooks/usePeriodComparison';
import useRestriction from '~/hooks/useRestriction';
import usePortfolio, { getVariables } from '~/pages/Portfolio/usePortfolio';
import useSimulationRules from '~/pages/Portfolio/useSimulationRules';

import SimulationSection from '../SimulationSection';
import { TableColumn, TableHeading, TableHorizontalAxis, TableSubheading, TableVerticalAxis } from '../Table';
import { useSimulationTable } from '../withSimulationTable';

const initialSubject = {
  argentinaBalanceAmount: 'Exportação ARG',
  uruguaiBalanceAmount: 'Exportação URU',
  walletBalanceAmount: 'Carteira',
  predictedBalanceAmount: 'Saldo Total',
  specialBallast: 'Lastro especial (MWm)',
  notSpecialBallast: 'Lastro não especial (MWm)',
  globalBallast: 'Lastro global (MWm)',
};

function SimulationDRE() {
  const { simulationPeriods } = useSimulationTable();
  const { isPortfolio } = useSimulationRules();
  const getColor = usePeriodComparison();
  const { restrict } = useRestriction();
  const variablesFilter = getVariables(usePortfolio());
  const { energyType, priceType, periodType, contractFlags, accountTypes, hasGiro, giro, accountingPortfolio } = variablesFilter;

  const [isPredictableOpen, setIsPredictableOpen] = useState(false);
  const [isCCEEAmountOpen, setIsCCEEAmountOpen] = useState(false);
  const [subject, setSubject] = useState(initialSubject);

  const getNumberFormat = (
    value: number,
    options: Intl.NumberFormatOptions = {
      currency: 'BRL',
      style: 'currency',
      minimumFractionDigits: 0,
    },
  ) => new Intl.NumberFormat('pt-BR', options).format(value);

  const hasValue = (value: any) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }

    return value !== undefined && value !== null && value !== '';
  };

  const checkFilterValues = () =>
    hasValue(energyType) ||
    hasValue(priceType) ||
    hasValue(periodType) ||
    hasValue(contractFlags) ||
    hasValue(accountTypes) ||
    hasValue(hasGiro) ||
    hasValue(giro) ||
    hasValue(accountingPortfolio);

  useEffect(() => {
    if (checkFilterValues()) {
      setSubject(initialSubject);
    } else {
      const updatedSubject = {
        cceeAmount: 'Liquidação CCEE',
        ...initialSubject,
      };

      setSubject(updatedSubject);
    }
  }, [energyType, priceType, periodType, contractFlags, accountTypes, hasGiro, giro, accountingPortfolio]);

  return (
    <>
      <SimulationSection label="Resultado" />
      <TableHorizontalAxis>
        <TableVerticalAxis position="sticky" left="0" gridTemplateColumns="200px" zIndex="1">
          {Object.keys(subject).map((operation) => {
            const isPredictable = /predictedBalanceAmount/.test(operation);
            const isCCEEAmount = /cceeAmount/.test(operation);

            return (
              <Fragment key={operation}>
                <TableHeading
                  fontWeight={isPredictable || isCCEEAmount ? 600 : 400}
                  isOpen={(isPredictable && isPredictableOpen) || (isCCEEAmount && isCCEEAmountOpen)}
                  onToggle={
                    isPredictable
                      ? () => setIsPredictableOpen(!isPredictableOpen)
                      : isCCEEAmount
                      ? () => setIsCCEEAmountOpen(!isCCEEAmountOpen)
                      : undefined
                  }
                >
                  {subject[operation]}
                </TableHeading>
                {isPredictable && isPredictableOpen && (
                  <>
                    <TableSubheading>Cenário alto</TableSubheading>
                    <TableSubheading>Cenário baixo</TableSubheading>
                    <TableSubheading>Curva Dcide</TableSubheading>
                    <TableSubheading>Curva Bbce</TableSubheading>
                    {!isPortfolio &&
                      restrict(
                        'SIMULATION_INTEREST_RATE',
                        <>
                          <TableSubheading>Fator de desconto</TableSubheading>
                          <TableSubheading>Valor presente</TableSubheading>
                        </>,
                      )}
                  </>
                )}
                {isCCEEAmount && isCCEEAmountOpen && (
                  <>
                    <TableSubheading>Efeito descolamento</TableSubheading>
                    <TableSubheading>Efeito modulação</TableSubheading>
                    <TableSubheading>Encargos varejista</TableSubheading>
                    <TableSubheading>Efeito saldo</TableSubheading>
                  </>
                )}
              </Fragment>
            );
          })}
        </TableVerticalAxis>
        {simulationPeriods?.map(({ period, dre }) => {
          const color = getColor(period);

          return (
            <TableVerticalAxis key={period} bgColor={color}>
              <TableColumn emphasis>{getNumberFormat(dre.cceeAmount)}</TableColumn>
              {isCCEEAmountOpen && (
                <>
                  <TableColumn>{getNumberFormat(dre.submarketDecoupling || 0)}</TableColumn>
                  <TableColumn>{getNumberFormat(dre.modulation || 0)}</TableColumn>
                  <TableColumn>{getNumberFormat(dre.retailerCharges || 0)}</TableColumn>
                  <TableColumn>{getNumberFormat(dre.ballanceEffect || 0)}</TableColumn>
                </>
              )}
              <TableColumn>{getNumberFormat(dre.argentinaBalanceAmount)}</TableColumn>
              <TableColumn>{getNumberFormat(dre.uruguaiBalanceAmount)}</TableColumn>
              <TableColumn>{getNumberFormat(dre.walletBalanceAmount)}</TableColumn>
              <TableColumn emphasis>{getNumberFormat(dre.predictedBalanceAmount)}</TableColumn>
              {isPredictableOpen && (
                <>
                  <TableColumn>{getNumberFormat(dre['highBalanceAmount'])}</TableColumn>
                  <TableColumn>{getNumberFormat(dre['lowBalanceAmount'])}</TableColumn>
                  <TableColumn>{getNumberFormat(dre['dcideBalanceAmount'])}</TableColumn>
                  <TableColumn>{getNumberFormat(dre['bbceBalanceAmount'])}</TableColumn>
                  {!isPortfolio &&
                    restrict(
                      'SIMULATION_INTEREST_RATE',
                      <>
                        <TableColumn>
                          {Intl.NumberFormat('pt-br', { minimumFractionDigits: 6, maximumFractionDigits: 6 }).format(
                            dre['pvFactor'],
                          )}
                        </TableColumn>
                        <TableColumn>{getNumberFormat(dre['pvTotalAmount'])}</TableColumn>
                      </>,
                    )}
                </>
              )}
              <TableColumn>{getNumberFormat(dre.specialBallast, { minimumFractionDigits: 2 })}</TableColumn>
              <TableColumn>{getNumberFormat(dre.notSpecialBallast, { minimumFractionDigits: 2 })}</TableColumn>
              <TableColumn>{getNumberFormat(dre.globalBallast, { minimumFractionDigits: 2 })}</TableColumn>
            </TableVerticalAxis>
          );
        })}
        <TableVerticalAxis gridTemplateColumns="initial" width="100%">
          <TableColumn showSkeleton={false}>&nbsp;</TableColumn>
          <TableColumn showSkeleton={false}>&nbsp;</TableColumn>
          {isPredictableOpen && (
            <>
              <TableColumn showSkeleton={false}>&nbsp;</TableColumn>
              <TableColumn showSkeleton={false}>&nbsp;</TableColumn>
            </>
          )}
          <TableColumn showSkeleton={false}>&nbsp;</TableColumn>
          <TableColumn showSkeleton={false}>&nbsp;</TableColumn>
          <TableColumn showSkeleton={false}>&nbsp;</TableColumn>
        </TableVerticalAxis>
      </TableHorizontalAxis>
    </>
  );
}

export default SimulationDRE;
